import React, { useState } from 'react';
import MainLayout from '../../components/MainLayout';
import { useMutation } from '@tanstack/react-query';
import { adminauth, deleteUser, line, addBarber, getbarber, deleteBarber } from '../../services/index/users';
import { useEffect } from 'react';
import { toast} from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const DashBoard = ({info}) => {
  info.on("new_user", () => {
    window.location.reload();
  })

  const [user,setUser] = useState([])
  const [barber,setBarber] = useState('')
  const [barberLine, setBarberLine] = useState([])

  const navigate = useNavigate();
  const { mutate: verify } = useMutation({
    mutationFn: async () => {
      await adminauth();
    },onSuccess: () => {
      toast.success("Welcome")
    },onError:(error) =>{
      navigate('/login')
      toast.error(error.message)
    }
  });

  const { mutate: addbarb } = useMutation({
    mutationFn: async (name) => 
    {
      await addBarber(name);
      setBarber('');
      
    },onSuccess: () => {
      toast.success("added")
      

      
    }
  });

  
  const { mutate: removeUser } = useMutation({
    mutationFn: async (x) => 
    {
      await deleteUser(x);
    },onSuccess: () => {
      toast.success("removed")
    }
  });

  const { mutate: removebarber } = useMutation({
    mutationFn: async (barber_id) => 
    {
      await deleteBarber(barber_id);
    },onSuccess: () => {
      toast.success("removed")
    }
  });

  const {mutate:barbline} = useMutation({
    mutationFn:async () => {
      return await getbarber();
    }, onSuccess: (data) => {
      console.log(data)
      setBarberLine(data);
    }
  })


  const {mutate:curline} = useMutation({
    mutationFn:async () => {
      return line();
    }, onSuccess: (data) => {
      console.log(data)
      setUser(data);
      
    }
  })

  useEffect(() => {
    (async() => {
      verify()
      barbline()
      curline()
      
    })()
  },[verify,barbline,curline,])

  const deletebarb = async (barber_id) => {
    removebarber(barber_id)
    barbline()
    info.emit("added_new", "removed barber")
  }

  const deleteUsers = async (x) =>{
    removeUser(x)
    curline()
    info.emit("added_new", "removed user")
  }

  const Change = event => {
    if(event.target.id === "barber"){
      setBarber(event.target.value)
    }  
  }

  const addABarber = async (name) => {
    try {
      addbarb(name);
      setBarber('');
      // Refresh the list of barbers after adding a new one
      barbline();
      info.emit("added_new", "removed barber")
    } catch (error) {
      toast.error(error.message);
    }
  };
  
  return (
    
    <MainLayout>
      <section className=' bg-black text-white font-body min-h-screen p-3'>
        <div className= ' flex flex-col justify-center'>
          <form className='flex flex-col justify-center items-center' >
              <label className='m-2'>barbers on clock</label> 
              <input 
                className=' text-black'
                id = 'barber'
                type = "text"
                value = {barber}
                placeholder='Enter a barber'
                onChange={Change}/>
              <button className='border px-4 m-2' onClick={()=>addABarber(barber)} >add</button> 
          </form>
          <div>
            <div className='flex justify-center p-2'>
              <table className="table-auto border border-slate-500">
                <caption>Barbers working today:</caption>
                <thead>
                  <tr  className=" bg-gray-500">
                    <th className=' px-8'>Barber</th>
                    <th className=' px-8'>clear below</th>
                  </tr>
                </thead>
                <tbody>
                  {barberLine.map((barber,index) => (
                    
                    <tr key={index} className='border border-separate'>
                      <td className="px-8 py-5 border border-separate">{barber.name}</td>
                      <td><button
                          onClick={() => deletebarb(barber.id)}
                          className='flex justify-center bg-[#D32828] rounded-lg w-[90%] mx-auto items-center'>

                            remove
                    </button></td>
                  </tr>
            
                  )) }
                  
                </tbody>
              </table>

            </div>
          </div>
        </div>

        <div className='flex justify-center'>       
          <table className="table-auto border border-slate-500">
            <caption>current Line</caption>
            <thead>
              <tr  className=" bg-gray-500" >
                <th className=' px-8'>Name</th>
                <th className="px-8"> barber</th>
                <th className="px-8">services</th>
                <th className="px-8">Booking time</th>
                <th className="px-8">ETA</th>
                <th className="px-8">Done cutting?</th>
              </tr>
            </thead>
            <tbody>
            {user.map((item,index)=> (
                      
                      <tr className='border border-separate' key={index}>
                        <td className="px-8 py-5 border border-separate">{item.name}</td>
                        <td className="px-8 border border-separate">{item.barber}</td>
                        <td className="px-8 border border-separate">{item.service}</td>
                        <td className="px-8 border border-separate">{formatTime(item.time)}</td>
                        <td className="px-8 border border-separate">{item.arrivalTime}</td>
                        <td><button
                        className='flex justify-center bg-[#D32828] rounded-lg w-[90%] mx-auto items-center'
                        onClick={() => deleteUsers(item.id)}
                        
                        >

                          remove
                        </button></td>
                      </tr>
                      ))}

              </tbody>
            </table>
          </div> 
         
      </section>
    </MainLayout>
  );
};

const formatTime = (timeString) => {
  const date = new Date(timeString);
  return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
};

export default DashBoard;
